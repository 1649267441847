import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { crisp } from '@/plugins/crisp'
import { subscriptionService } from '@/services/subscription'

export const useUserStore = defineStore('user', () => {
  const user = ref({
    name: '',
    email: '',
    phone: '',
    custom_domain_enabled: false,
    language: '',
    cloakup_active: false,
    host_active: false,
    subscriptions: [
      {
        plan: {}
      }
    ]
  })
  const usage = ref(null)
  const isFetchingUsage = ref(false)

  const cloakupSubscription = computed(() =>
    user.value?.subscriptions?.find(s => s.plan.product === 'cloakup')
  )
  const hostSubscription = computed(() =>
    user.value?.subscriptions?.find(s => s.plan.product === 'host')
  )

  // TODO: refactor
  const isTrial = computed(() => {
    if (!cloakupSubscription.value?.id) {
      return false
    }

    const timestampAtual = Date.now()
    const timestampCriacao = new Date(
      cloakupSubscription.value.created_at
    ).getTime()
    const milissegundosRestantes
      = 7 * 24 * 60 * 60 * 1000 - (timestampAtual - timestampCriacao)
    const diasRestantes = Math.ceil(
      milissegundosRestantes / (24 * 60 * 60 * 1000)
    )

    return diasRestantes > 0
  })

  function updateUser(data) {
    user.value = data
  }

  function updateUsage(data) {
    usage.value = data
  }

  function updateLanguage(data) {
    user.value.language = data
  }

  async function fetchUsage() {
    if (usage.value) {
      return
    }
    if (!user.value?.cloakup_active) {
      return
    }

    isFetchingUsage.value = true

    try {
      const { data } = await subscriptionService.getUsage(
        cloakupSubscription.value.id
      )
      usage.value = data
      crisp().setPlanUsage(data.usage, data.limit)

      // if (
      //   data.usage >= data.limit &&
      //   cloakupSubscription.value?.charge_additional &&
      //   cloakupSubscription.value?.status === "active"
      // ) {
      //   crisp().sendMessage(
      //     "MENSAGEM AUTOMÁTICA: Você excedeu o limite do plano contratado. Se deseja evitar interrupções no serviço, faça o upgrade do seu plano. Caso não seja feito upgrade, será efetuada uma **cobrança** pelo uso adicional."
      //   );
      // }
    } catch (error) {
      console.log(error, 'error')
    }

    isFetchingUsage.value = false
  }

  return {
    updateUser,
    updateUsage,
    fetchUsage,
    updateLanguage,
    isFetchingUsage: computed(() => isFetchingUsage.value),
    user: computed(() => user.value),
    usage: computed(() => usage.value),
    isTrial: computed(() => isTrial.value),
    cloakupSubscription: computed(() => cloakupSubscription.value),
    hostSubscription: computed(() => hostSubscription.value),
    requestRaw: computed(() => user.value?.request_raw_enabled || false)
  }
})
