<script setup>
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import SectionHeading from '@/components/common/SectionHeading.vue'
import CampaignIntegrationDomain from '@/components/partials/campaign/integrations/CampaignIntegrationDomain.vue'
import CampaignIntegrationJavascript
  from '@/components/partials/campaign/integrations/CampaignIntegrationJavascript.vue'
import CampaignIntegrationPHP from '@/components/partials/campaign/integrations/CampaignIntegrationPHP.vue'
import CampaignIntegrationWordpress from '@/components/partials/campaign/integrations/CampaignIntegrationWordpress.vue'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'
import { useUserStore } from '@/stores/user'

const userStore = useUserStore()
const { t } = useI18n()
useHead({
  title: t('pages.campaign.integrations.title')
})
</script>

<template>
  <div class="space-y-5">
    <SectionHeading
      :title="$t('pages.campaign.integrations.title')"
      :description="$t('pages.campaign.integrations.description')"
    />
    <Accordion
      type="single"
      collapsible
    >
      <AccordionItem value="domain">
        <AccordionTrigger>
          <span>
            {{ $t('common.domain') }}
          </span>
        </AccordionTrigger>
        <AccordionContent>
          <CampaignIntegrationDomain v-if="userStore.user?.custom_domain_enabled" />
          <p v-else>
            {{ $t('pages.campaign.integrations.domain_unavailable') }}
          </p>
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="php">
        <AccordionTrigger>PHP</AccordionTrigger>
        <AccordionContent>
          <CampaignIntegrationPHP />
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="wp">
        <AccordionTrigger>Wordpress</AccordionTrigger>
        <AccordionContent>
          <CampaignIntegrationWordpress />
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="js">
        <AccordionTrigger>Javascript</AccordionTrigger>
        <AccordionContent>
          <CampaignIntegrationJavascript />
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  </div>
</template>
