<script setup>
import { CheckIcon, ChevronDownIcon } from '@heroicons/vue/24/solid'
import { onMounted } from 'vue'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'

import { useCampaignStore } from '@/stores/campaign.js'

const campaignStore = useCampaignStore()

onMounted(() => {
  if (!campaignStore.campaign?.length) {
    campaignStore.fetchCampaigns()
  }
})
</script>

<template>
  <Breadcrumb>
    <BreadcrumbList>
      <BreadcrumbItem>
        <BreadcrumbLink as-child>
          <RouterLink to="/">
            {{ $t('common.dashboard') }}
          </RouterLink>
        </BreadcrumbLink>
      </BreadcrumbItem>

      <BreadcrumbSeparator />

      <BreadcrumbItem>
        <BreadcrumbLink as-child>
          <RouterLink to="/campaigns">
            {{ $t('common.campaigns') }}
          </RouterLink>
        </BreadcrumbLink>
      </BreadcrumbItem>

      <BreadcrumbSeparator />

      <BreadcrumbItem>
        <DropdownMenu>
          <DropdownMenuTrigger class="flex items-center gap-1">
            <BreadcrumbPage>
              {{ campaignStore.campaign?.name }}
            </BreadcrumbPage>
            <ChevronDownIcon class="ml-2 size-4" />
          </DropdownMenuTrigger>

          <DropdownMenuContent>
            <DropdownMenuItem
              v-for="(campaign, index) in campaignStore.campaigns"
              :key="campaign.id"
              :value="campaign.id"
              as-child
            >
              <RouterLink
                :to="`/campaigns/${campaign.id}`"
              >
                {{ campaign.name }}

                <CheckIcon
                  v-if="campaign.id === campaignStore.campaign?.id"
                  class="ml-auto size-4"
                />
              </RouterLink>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </BreadcrumbItem>
    </BreadcrumbList>
  </Breadcrumb>
</template>
