<script setup>
import ptBR from 'apexcharts/dist/locales/pt-br.json'
import { ref, watch } from 'vue'
import apexcharts from 'vue3-apexcharts'
import { useColorMode } from '@vueuse/core'
import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n'
import { formatNumber } from '@/helpers/format'

const props = defineProps({
  labels: {
    type: Array,
    default: () => []
  },
  data: {
    type: Object
  }
})

const mode = useColorMode()
const { t } = useI18n()

const options = ref({
  chart: {
    id: 'clicks-per-day',
    animations: {
      enabled: true,
      easing: 'easeinout',
      speed: 800,
      animateGradually: {
        enabled: true,
        delay: 150
      },
      dynamicAnimation: {
        enabled: true,
        speed: 350
      }
    },
    locales: [ptBR],
    defaultLocale: 'pt-br',
    toolbar: {
      show: false
    },
    zoom: {
      enabled: false
    },
    reverse: true
  },
  tooltip: {
    enabled: true,
    followCursor: true,
    custom({ dataPointIndex }) {
      const data = props.data[dataPointIndex]
      const sum = data.allowed + data.denied

      const percentAllowed = sum ? ((data.allowed / sum) * 100) : 0
      const percentDenied = sum ? ((data.denied / sum) * 100) : 0

      const allowedDot = '<span class="apexcharts-legend-marker" rel="1" data:collapsed="false" style="background: rgb(34, 197, 94) !important; color: rgb(34, 197, 94); height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 12px;"></span>'
      const deniedDot = '<span class="apexcharts-legend-marker" rel="1" data:collapsed="false" style="background: rgb(231, 76, 60) !important; color: rgb(231, 76, 60); height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 12px;"></span>'

      return `
            <div class="flex flex-col justify-center px-3 py-2 text-sm gap-2">
          <div class="flex items-center gap-2 justify-between">
              <div class="flex items-center gap-2">
                    ${allowedDot}
                    <p>
                      ${t('components.dashboard_chart.allowed')}
                    </p>
               </div>
                <span class="font-semibold"> ${formatNumber(data.allowed)} (${percentAllowed.toFixed(2)}%)</span>
           </div>
          <div class="flex items-center gap-2">
              <div class="flex items-center gap-2">
                  ${deniedDot}
                  <p>${t('components.dashboard_chart.denied')}</p>
              </div>

              <span class="font-semibold">${formatNumber(data.denied)} (${percentDenied.toFixed(2)}%)</span>
          </div>
        </div>
        `
    }
  },
  colors: ['#22c55e', '#ef4444'],
  stroke: {
    curve: 'smooth'
  },
  grid: {
    show: false
  },
  theme: {
    mode: mode.state.value === 'dark' ? 'dark' : 'light'
  },
  yaxis: {
    crosshairs: {
      show: false
    },
  },
  xaxis: {
    categories: props.data.map(item => item.date),
    tooltip: {
      enabled: false
    }
  }
})

const series = ref([
  {
    name: t('components.dashboard_chart.allowed'),
    data: props.data.map(item => item.allowed)
  },
  {
    name: t('components.dashboard_chart.denied'),
    data: props.data.map(item => item.denied)
  }
])

function updateChart() {
  series.value[0].data = props.data.map(item => item.allowed)
  series.value[1].data = props.data.map(item => item.denied)

  options.value = {
    ...options.value,
    xaxis: {
      categories: props.data.map(item => item.date),
      tooltip: {
        enabled: false
      },
      labels: {
        formatter(value) {
          if (!value) {
            return
          }
          if (!value.toString().includes('-')) {
            return value
          }

          const date = new Date(value?.toString().replaceAll('-', '/'))

          return dayjs(date).format('DD/MM')
        }
      }
    }
  }
}

watch(props, updateChart, {
  deep: true,
  immediate: true
})

watch(mode, () => {
  options.value = {
    ...options.value,
    ...{
      theme: {
        mode: mode.state.value === 'dark' ? 'dark' : 'light'
      }
    }
  }
})
</script>

<template>
  <apexcharts
    type="line"
    :options="options"
    height="600px"
    :series="series"
  />
</template>

<style>
.apexcharts-text.apexcharts-xaxis-label tspan,
.apexcharts-text.apexcharts-yaxis-label tspan {
  @apply text-muted-foreground;
}
.apexcharts-canvas > svg {
  background-color: transparent !important;
}
</style>
