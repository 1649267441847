<script setup>
import { useI18n } from 'vue-i18n'
import { useUserStore } from '@/stores/user'
import { formatRequestDeniedReason } from '@/helpers/format-request-denied-reason'
import { formatDate } from '@/helpers/date'

defineProps({
  request: {
    type: Object,
    required: true
  }
})
const { locale } = useI18n()

const userStore = useUserStore()

function formatQuery(string) {
  const query = new URLSearchParams(string)
  const formattedQuery = Array.from(query.entries()).map(
    ([key, value]) => `${key}: ${value}`
  )

  return formattedQuery
}
</script>

<template>
  <div class="divide-y divide-border px-4 sm:px-0">
    <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4">
      <dt class="text-sm font-medium text-muted-foreground">
        {{ $t('common.created_at') }}
      </dt>
      <dd class="mt-2 text-sm  sm:col-span-2 sm:mt-0">
        <p>
          {{ formatDate(request.created_at, {
            locale,
          }) }}
        </p>
      </dd>
    </div>
    <div
      v-if="!request.allowed"
      class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4"
    >
      <dt class="text-sm font-medium text-muted-foreground">
        {{ $t('common.status') }}
      </dt>
      <dd class="mt-2 text-sm  sm:col-span-2 sm:mt-0">
        <p class="text-red-500">
          {{
            formatRequestDeniedReason(request.denied_reason, $t)
              ?? request.denied_reason
          }}
        </p>
      </dd>
    </div>
    <div
      v-if="userStore.requestRaw"
      class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4"
    >
      <dt class="text-sm font-medium text-muted-foreground">
        IP
      </dt>
      <dd class="mt-2 text-sm  sm:col-span-2 sm:mt-0">
        <p>
          {{ request.ip }}
        </p>
      </dd>
    </div>
    <div
      v-if="userStore.requestRaw"
      class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4"
    >
      <dt class="text-sm font-medium text-muted-foreground">
        ISP
      </dt>
      <dd class="mt-2 text-sm  sm:col-span-2 sm:mt-0">
        <p>
          {{ request.isp }}
        </p>
      </dd>
    </div>
    <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4">
      <dt class="text-sm font-medium text-muted-foreground">
        {{ $t('common.page') }}
      </dt>
      <dd class="mt-2 text-sm  sm:col-span-2 sm:mt-0">
        <p>
          {{ request.page }}
        </p>
      </dd>
    </div>
    <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4">
      <dt class="text-sm font-medium text-muted-foreground">
        {{ $t('common.device') }}
      </dt>
      <dd class="mt-2 text-sm  sm:col-span-2 sm:mt-0">
        <p>
          {{ $t(`common.devices.${request.device}`) }}
        </p>
      </dd>
    </div>
    <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4">
      <dt class="text-sm font-medium text-muted-foreground">
        {{ $t('common.os') }}
      </dt>
      <dd class="mt-2 text-sm  sm:col-span-2 sm:mt-0">
        <p>
          {{ request.os }}
        </p>
      </dd>
    </div>
    <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4">
      <dt class="text-sm font-medium text-muted-foreground">
        {{ $t('common.browser') }}
      </dt>
      <dd class="mt-2 text-sm  sm:col-span-2 sm:mt-0">
        <p>
          {{ request.browser }}
        </p>
      </dd>
    </div>
    <div
      v-if="userStore.requestRaw"
      class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4"
    >
      <dt class="text-sm font-medium text-muted-foreground">
        User Agent
      </dt>
      <dd class="mt-2 text-sm  sm:col-span-2">
        <p>
          {{ request.user_agent }}
        </p>
      </dd>
    </div>
    <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4">
      <dt class="text-sm font-medium text-muted-foreground">
        {{ $t('common.country') }}
      </dt>
      <dd class="mt-2 text-sm  sm:col-span-2">
        <div class="flex items-center gap-2">
          <img
            class="inline-block w-10"
            :src="`/img/flags/${request.country?.toLowerCase()}.svg`"
            :alt="request.country"
            loading="lazy"
          >
          <p>
            {{ request.country }}
          </p>
        </div>
      </dd>
    </div>
    <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4">
      <dt class="text-sm font-medium text-muted-foreground">
        {{ $t('common.referrer') }}
      </dt>
      <dd class="mt-2 text-sm  sm:col-span-2 sm:mt-0">
        <p>
          {{ request.referer }}
        </p>
      </dd>
    </div>
    <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4">
      <dt class="text-sm font-medium text-muted-foreground">
        {{ $t('common.domain') }}
      </dt>
      <dd class="mt-2 text-sm  sm:col-span-2 sm:mt-0">
        <p>
          {{ request.domain }}
        </p>
      </dd>
    </div>
    <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4">
      <dt class="text-sm font-medium text-muted-foreground">
        {{ $t('common.query_string') }}
      </dt>
      <dd class="mt-2 text-sm  sm:col-span-2 sm:mt-0">
        <ul
          v-if="request.query"
          class="mt-2 list-inside list-disc"
        >
          <li
            v-for="(param, index) in formatQuery(request.query)"
            :key="index"
          >
            {{ param }}
          </li>
        </ul>
      </dd>
    </div>
  </div>
</template>
