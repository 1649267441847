<script setup>
import dayjs from 'dayjs'
import { CalendarDaysIcon } from '@heroicons/vue/24/solid'
import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/v-calendar'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

const props = defineProps({
  presets: {
    type: Array,
    default: () => [],
  },
  min: {
    type: Date,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  locale: {
    type: String,
    default: 'pt-BR'
  }
})

const date = defineModel({
  required: true,
})

function updateDate(value) {
  const presetDate = props.presets.find(preset => preset.id === value)
  if (presetDate) {
    date.value.start = presetDate.value.start
    date.value.end = presetDate.value.end
  }
}
</script>

<template>
  <div :class="cn('grid gap-2', $attrs.class ?? '')">
    <Popover>
      <PopoverTrigger as-child>
        <Button
          id="date"
          variant="outline"
          :class="cn(
            ' justify-start text-left font-normal word-break truncate',
            !date && 'text-muted-foreground',
          )"
          :disabled="disabled"
          :loading="loading"
        >
          <CalendarDaysIcon
            v-if="!loading"
            class="mr-2 size-5 shrink-0 opacity-50"
          />

          <span class="word-break truncate ">
            {{ date.start ? (
              date.end ? `${dayjs(date.start).format('DD/MM/YYYY HH:mm')} - ${dayjs(date.end).format('DD/MM/YYYY HH:mm')}`
              : dayjs(date.start).format('DD/MM/YYYY HH:mm')
            ) : $t('components.date_picker.select_a_date') }}
          </span>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        class="flex w-auto max-w-xs flex-col space-y-2 p-2"
        align="start"
      >
        <Select
          v-if="props.presets.length"
          @update:model-value="updateDate"
        >
          <SelectTrigger>
            <SelectValue
              :placeholder="$t('components.date_picker.select_predefined_date')"
            />
          </SelectTrigger>
          <SelectContent position="popper">
            <SelectItem
              v-for="item in props.presets"
              :key="item.value"
              :value="item.id"
            >
              {{ item.label }}
            </SelectItem>
          </SelectContent>
        </Select>

        <Calendar
          v-model.range="date"
          mode="date"
          :min-date="props.min"
          :max-date="new Date()"
          is24hr
          :locale="locale"
        >
          <template #footer>
            <div class="mt-6 flex w-full border-t border-accent pt-4">
              <div class="w-1/2">
                <strong>
                  {{ $t('common.start') }}
                </strong>
                <Calendar
                  v-model="date.start"
                  mode="time"
                  is24hr
                  hide-time-header
                />
              </div>
              <div class="w-1/2">
                <strong>
                  {{ $t('common.end') }}
                </strong>
                <Calendar
                  v-model="date.end"
                  mode="time"
                  is24hr
                  hide-time-header
                />
              </div>
            </div>
          </template>
        </Calendar>
      </PopoverContent>
    </Popover>
  </div>
</template>

<style scoped>
select::-ms-expand {
    display: none;
}
</style>
