<script setup>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import jsVectorMap from 'jsvectormap'
import dayjs from 'dayjs'
import { toast } from 'vue-sonner'
import { ArrowTrendingUpIcon, CheckIcon, InboxIcon, XMarkIcon } from '@heroicons/vue/24/solid'
import { onBeforeRouteLeave } from 'vue-router'
import { useHead } from '@unhead/vue'
import { useI18n } from 'vue-i18n'
import DatePicker from '@/components/base/DatePicker.vue'
import MultiSelect from '@/components/base/MultiSelect.vue'
import CardStat from '@/components/common/CardStat.vue'
import PageHeading from '@/components/common/PageHeading.vue'
import DashboardChart from '@/components/partials/dashboard/DashboardChart.vue'
import { formatNumber } from '@/helpers/format'
import { campaignService } from '@/services/campaign'
import { reportService } from '@/services/report-service'
import BaseBarList from '@/components/base/BaseBarList.vue'
import { formatRequestDeniedReason } from '@/helpers/format-request-denied-reason'
import 'jsvectormap/dist/maps/world-merc'
import 'jsvectormap/src/scss/jsvectormap.scss'
import { countryList } from '@/static/countryList'
import EmptyState from '@/components/common/EmptyState.vue'
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import { usePresetDates } from '@/composables/usePresetDates'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'
import Skeleton from '@/components/ui/skeleton/Skeleton.vue'
import { fillMissingDates } from '@/helpers/date'

const { t, locale } = useI18n()
useHead({
  title: t('pages.reports.title')
})
const controller = new AbortController()
const {
  minDate,
  shortcuts
} = usePresetDates()

const countries = ref({})
const deniedReasons = ref([])
const dashboard = reactive({
  requests: []
})
const range = ref({
  start: dayjs().startOf('day').subtract(7, 'days').toDate(),
  end: dayjs().endOf('day').toDate(),
})
const campaigns = ref([])
const selectedCampaigns = ref([])
const isLoadingCampaigns = ref(false)
const isLoadingCountries = ref(false)
const isLoadingByDeniedReasons = ref(false)
const isLoadingByAllowed = ref(false)
const map = ref(null)

const searchParams = computed(() => {
  const params = {}

  params.campaigns = selectedCampaigns.value

  params.start_date = dayjs(range.value.start).unix() * 1000
  params.end_date = dayjs(range.value.end).unix() * 1000

  return params
})
const mapOptions = computed(() => {
  return {
    onRegionTooltipShow(event, tooltip, code) {
      const country = countryList.find(c => c.id === code)
      const countryName = country?.name ?? t('common.unknown')
      const accessCount = countries.value[code] ?? 0
      const accessText = Number(accessCount) > 1 ? 'Acessos' : 'Acesso'

      tooltip.css({
        backgroundColor: '#FFFFFF',
        color: '#171717'
      })
      tooltip.text(
        `
        <div class="flex gap-2">
          <img src="/img/flags/${code.toLowerCase()}.svg" class="w-8" />
          <div>
            <h5 class="font-semibold">${countryName}</h5>
            <p class="text-xs text-muted-foreground">
              ${formatNumber(accessCount)} ${accessText}
            </p>
      `,
        true
      )
    },
    visualizeData: {
      scale: ['#93c5fd', '#3b82f6', '#1d4ed8'],
      values: countries.value
    }
  }
})
const isPending = computed(() => {
  return isLoadingCountries.value || isLoadingByDeniedReasons.value || isLoadingByAllowed.value
})

const allowedRequests = computed(() => {
  return dashboard.requests.reduce((acc, item) => {
    return acc + item.allowed
  }, 0)
})
const blockedRequests = computed(() => {
  return dashboard.requests.reduce((acc, item) => {
    return acc + item.denied
  }, 0)
})
const totalRequests = computed(() => {
  return allowedRequests.value + blockedRequests.value
})

async function fetchByAllowed(params) {
  isLoadingByAllowed.value = true

  try {
    const { data } = await reportService.groupByAllowed({
      params,
      signal: controller.signal
    })

    dashboard.requests = fillMissingDates(params.start_date, params.end_date, data)
  } catch (e) {
    if (e.message === 'canceled') {
      return
    }

    console.log(e)

    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isLoadingByAllowed.value = false
  }
}

async function fetchByDeniedReason(params) {
  isLoadingByDeniedReasons.value = true
  try {
    const { data } = await reportService.groupByDeniedReason({
      params,
      signal: controller.signal
    })
    deniedReasons.value = Object.entries(data)
      .map((denied) => {
        return {
          name: formatRequestDeniedReason(denied[0], t) ?? t('denied_reasons.unknown'),
          value: denied[1].count,
          percentage: denied[1].average,
          color: denied[0] === 'accepted' ? 'bg-green-500 dark:bg-green-800' : 'bg-destructive'
        }
      })
      .sort((a, b) => b.value - a.value)
  } catch (e) {
    if (e.message === 'canceled') {
      return
    }

    console.log(e)

    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isLoadingByDeniedReasons.value = false
  }
}

async function fetchByCountry(params) {
  isLoadingCountries.value = true
  try {
    const { data } = await reportService.groupByCountry({
      params,
      signal: controller.signal
    })

    const obj = {}

    Object.entries(data).forEach((element) => {
      obj[element[1].country] = element[1].count
    })

    countries.value = obj
  } catch (e) {
    if (e.message === 'canceled') {
      return
    }

    console.log(e)

    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isLoadingCountries.value = false
  }
}

async function fetchDashboard(params) {
  await Promise.all([
    fetchByAllowed(params),
    fetchByDeniedReason(params),
    fetchByCountry(params)
  ])
}

async function fetchCampaigns() {
  isLoadingCampaigns.value = true
  try {
    const { data } = await campaignService.findAll({
      limit: 100,
      page: 1
    })

    campaigns.value = data.data
  } catch (e) {
    toast.success('Sucesso!', {
      description: 'Campanhas carregadas com sucesso',
    })
  } finally {
    isLoadingCampaigns.value = false
  }
}

watch(
  () => [range.value, selectedCampaigns.value],
  () => {
    fetchDashboard(searchParams.value)
  },
  {
    deep: true
  }
)

onMounted(async () => {
  await Promise.all([fetchCampaigns(), fetchDashboard(searchParams.value)])
})

onBeforeRouteLeave(() => {
  controller.abort()
})
</script>

<template>
  <div class="space-y-5">
    <div class="space-y-2">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink as-child>
              <RouterLink to="/">
                {{ $t('pages.reports.breadcrumb.dashboard') }}
              </RouterLink>
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbSeparator />

          <BreadcrumbItem>
            <BreadcrumbPage>
              {{ $t('pages.reports.breadcrumb.reports') }}
            </BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <PageHeading
        :title="t('pages.reports.title')"
      >
        <template #actions>
          <div class="grid grid-cols-1 items-center gap-2 lg:grid-cols-2">
            <MultiSelect
              v-model="selectedCampaigns"
              :options="campaigns"
              :icon="InboxIcon"
              :placeholder="$t('components.campaign_multiselect.placeholder')"
              :empty="$t('components.campaign_multiselect.empty')"
              :disabled="isPending"
              :loading="isPending"
            />
            <div class="w-72">
              <DatePicker
                v-model="range"
                :presets="shortcuts"
                :min="minDate"
                range
                :disabled="isPending"
                :loading="isPending"
                :locale="locale"
              />
            </div>
          </div>
        </template>
      </PageHeading>
    </div>

    <div class="space-y-5">
      <div>
        <dl class="mt-5 grid grid-cols-1 gap-5  xl:grid-cols-12">
          <div class="col-span-12 xl:col-span-3">
            <Skeleton
              v-if="isLoadingByAllowed"
              class="h-[108px]"
            />
            <CardStat
              v-else
              :icon="ArrowTrendingUpIcon"
              :title="$t('total_requests')"
              :subtitle="formatNumber(totalRequests)"
            />
          </div>

          <div class="col-span-12 xl:col-span-3">
            <Skeleton
              v-if="isLoadingByAllowed"
              class="h-[108px]"
            />
            <CardStat
              v-else
              :icon="CheckIcon"
              variant="success"
              :title="$t('accepted_requests')"
              :subtitle="formatNumber(allowedRequests)"
            />
          </div>

          <div class="col-span-12 xl:col-span-3">
            <Skeleton
              v-if="isLoadingByAllowed"
              class="h-[108px]"
            />
            <CardStat
              v-else
              :icon="XMarkIcon"
              variant="danger"
              :title="$t('blocked_requests')"
              :subtitle="formatNumber(blockedRequests)"
            />
          </div>

          <div class="col-span-12 xl:col-span-8">
            <Skeleton
              v-if="isLoadingByAllowed"
              class="h-[730px]"
            />
            <Card v-else>
              <CardHeader>
                <CardTitle> {{ $t('request_per_day') }}</CardTitle>
              </CardHeader>
              <CardContent>
                <DashboardChart
                  v-if="dashboard.requests?.length"
                  :data="dashboard.requests"
                />
              </CardContent>
            </Card>
          </div>

          <div class="col-span-12 xl:col-span-4">
            <Skeleton
              v-if="isLoadingCountries"
              class="h-[730px]"
            />
            <Card v-else>
              <CardHeader>
                <CardTitle>
                  {{ $t('request_per_country') }}
                </CardTitle>
              </CardHeader>
              <CardContent>
                <vuevectormap
                  ref="map"
                  width="100%"
                  map="world_merc"
                  :options="mapOptions"
                />
              </CardContent>
            </Card>
          </div>

          <div class="col-span-12 lg:col-span-12">
            <Skeleton
              v-if="isLoadingByDeniedReasons"
              class="h-[300px]"
            />
            <Card v-else>
              <CardHeader>
                <CardTitle>
                  {{ $t('pages.reports.request_resume') }}
                </CardTitle>
              </CardHeader>
              <CardContent>
                <template v-if="!deniedReasons?.length">
                  <EmptyState />
                </template>
                <template v-else>
                  <div class="mb-4 flex justify-between">
                    <span class="text-sm font-semibold ">{{ $t('reason') }}</span>
                    <span class="text-sm font-semibold ">
                      {{ $t('access') }}
                    </span>
                  </div>
                  <BaseBarList
                    :list="deniedReasons"
                    :loading="isLoadingByDeniedReasons"
                  >
                    <template #value="{ item }">
                      <span class="text-sm font-semibold ">{{ formatNumber(item.value) }} ({{
                        item.percentage
                      }}%)</span>
                    </template>
                  </BaseBarList>
                </template>
              </CardContent>
            </Card>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<style>
.jvm-container {
  height: 615px !important;
}
</style>
