<script setup>
import { computed } from 'vue'
import { Skeleton } from '@/components/ui/skeleton'

const props = defineProps({
  list: {
    type: Array,
    required: true,
    validator: (value) => {
      return value.every((item) => {
        return item.hasOwnProperty('name') && item.hasOwnProperty('value')
      })
    }
  },
  loading: {
    type: Boolean,
    default: false
  }
})

const sumValue = computed(() => {
  return props.list.reduce((acc, item) => {
    return acc + item.value
  }, 0)
})

function getPercentage(value) {
  return ((value * 100) / sumValue.value).toFixed(2)
}
</script>

<template>
  <div class="flex justify-between space-x-6">
    <div class="relative w-full">
      <div
        v-if="loading"
        class="space-y-2"
      >
        <div
          v-for="i in 9"
          :key="i"
          class="flex cursor-pointer items-center justify-between rounded-md px-2 hover:bg-foreground/15"
        >
          <Skeleton class="h-5" />
        </div>
      </div>
      <div v-else>
        <div
          v-for="(item, index) in list"
          :key="item.name"
          class="flex cursor-pointer items-center justify-between rounded-md px-2 hover:bg-foreground/15"
        >
          <div
            class="-ml-2 mb-2 flex h-9 items-center rounded-md"
            :style="`width: ${getPercentage(item.value)}%`"
            :class="[item?.color ? item.color : 'bg-blue-200']"
          >
            <div class="absolute left-2 flex max-w-full">
              <p
                class="truncate whitespace-nowrap text-sm  leading-6"
              >
                {{ item.name }}
              </p>
            </div>
          </div>
          <div class="min-w-min text-right">
            <div class="mb-2 flex h-9 items-center justify-end">
              <p class="truncate whitespace-nowrap text-sm font-semibold">
                <template v-if="!$slots.value">
                  {{ item.value }}
                </template>
                <slot
                  name="value"
                  :item="item"
                  :index="index"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
