<script setup>
import { computed, ref, watch } from 'vue'
import dayjs from 'dayjs'
import { toast } from 'vue-sonner'
import { useRoute } from 'vue-router'
import { ArrowPathIcon, CheckIcon, XMarkIcon } from '@heroicons/vue/24/solid'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import RequestDetails from '@/components/partials/RequestDetails.vue'
import BaseTable from '@/components/base/BaseTable.vue'
import SectionHeading from '@/components/common/SectionHeading.vue'
import { campaignService } from '@/services/campaign'
import {
  formatRequestDeniedReason,
  formatRequestDeniedReasonShorted
} from '@/helpers/format-request-denied-reason'
import { useUserStore } from '@/stores/user'
import { getBrowserIcon, getDeviceIcon, getOsIcon } from '@/helpers/requests'
import Button from '@/components/ui/button/Button.vue'
import CampaignRequestFilters from '@/components/partials/campaign/CampaignRequestFilters.vue'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/components/ui/tooltip'

const { t, locale } = useI18n()
useHead({
  title: t('pages.campaign.requests.title')
})
const userStore = useUserStore()
const route = useRoute()

const isLoading = ref(false)
const hasError = ref(false)
const requests = ref([])

const requestTableHeader = [
  {
    label: t('common.created_at'),
    value: 'created_at',
    format: value => dayjs(value).format('DD/MM/YYYY HH:mm:ss')
  },
  {
    label: t('common.status'),
    value: 'denied_reason'
  },
  {
    label: t('common.page'),
    value: 'page'
  },
  ...(userStore.requestRaw
    ? [
        {
          label: 'IP',
          value: 'ip'
        }
      ]
    : []),
  ...(userStore.requestRaw
    ? [
        {
          label: 'ISP',
          value: 'isp'
        }
      ]
    : []),
  {
    label: t('common.device'),
    value: 'device'
  },
  {
    label: 'os',
    value: 'os'
  },
  {
    label: t('common.browser'),
    value: 'browser'
  },
  {
    label: t('common.location'),
    value: 'country'
  },
  {
    label: t('common.referrer'),
    value: 'referer'
  },
  {
    label: t('common.domain'),
    value: 'domain'
  }
]

const searchParams = computed(() => {
  const payload = {
    limit: 10
  }

  payload.allowed = route.query.allowed
  payload.isp = route.query.isp
  payload.device = route.query.device
  payload.domain = route.query.domain
  payload.referer = route.query.referer

  if (route.query?.start_date) {
    payload.start_date = dayjs(route.query?.start_date).unix() * 1000
  }

  if (route.query?.end_date) {
    payload.end_date = dayjs(route.query?.end_date).unix() * 1000
  }

  if (route.query?.cursor) {
    payload.cursor = route.query?.cursor
  }

  return payload
})

async function fetchRequests(id, params) {
  isLoading.value = true
  try {
    const { data } = await campaignService.requests(id, params)
    if (!params.cursor) {
      requests.value = data.data
    } else {
      requests.value = [...requests.value, ...data.data]
    }
  } catch (e) {
    hasError.value = true

    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isLoading.value = false
  }
}

watch(
  () => [route.query],
  (newValue) => {
    fetchRequests(route.params.id, searchParams.value)
  },
  {
    immediate: true
  }
)
</script>

<template>
  <div class="space-y-5">
    <section>
      <SectionHeading
        :title="$t('pages.campaign.requests.title')"
        :description="$t('pages.campaign.requests.description')"
      >
        <template #actions>
          <div class="space-x-2">
            <Button
              variant="outline"
              @click="fetchRequests(route.params.id, searchParams)"
            >
              <ArrowPathIcon class="mr-2 size-5" />
              {{ $t('common.refresh') }}
            </Button>
            <CampaignRequestFilters />
          </div>
        </template>
      </SectionHeading>

      <div class="mt-10">
        <BaseTable
          :loading="isLoading"
          :error="hasError"
          :headers="requestTableHeader"
          :columns="requests"
        >
          <template #col-denied_reason="{ column }">
            <div class="flex gap-2">
              <CheckIcon
                v-if="column.allowed"
                class="size-5 text-success"
              />
              <TooltipProvider
                :disabled="column.allowed"
              >
                <Tooltip>
                  <TooltipTrigger class="flex gap-2">
                    <XMarkIcon
                      v-if="!column.allowed"
                      class="size-5 text-destructive"
                    />
                    <span
                      v-if="!column.allowed"
                      class="text-destructive"
                    >{{
                      formatRequestDeniedReasonShorted(column.denied_reason, $t)
                    }}</span>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>
                      {{ formatRequestDeniedReason(column.denied_reason, $t)
                        ?? column.denied_reason }}
                    </p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          </template>
          <template #col-ip="{ column }">
            <div class="flex flex-col gap-2">
              <span>{{ column.ip }}</span>
              <span class="text-xs">{{ column.isp }}</span>
            </div>
          </template>
          <template #col-device="{ column }">
            <component
              :is="getDeviceIcon(column.device)"
              class="size-6"
            />
          </template>
          <template #col-browser="{ column }">
            <component
              :is="getBrowserIcon(column.browser)"
              class="size-6"
            />
          </template>
          <template #col-os="{ column }">
            <component
              :is="getOsIcon(column.os)"
              class="size-6"
            />
          </template>
          <template #col-country="{ column }">
            <div class="flex items-center">
              <img
                class="inline-block w-10"
                :src="`/img/flags/${column.country?.toLowerCase()}.svg`"
                :alt="column.country"
                loading="lazy"
              >
            </div>
          </template>
          <template #expand="{ column }">
            <RequestDetails :request="column" />
          </template>

          <template #pagination>
            <Button
              class="w-full"
              :loading="isLoading"
              variant="outline"
              :disabled="!requests.length"
              @click="$router.push({ query: {
                ...$route.query,
                cursor: requests[requests.length - 1].created_at,
              } })"
            >
              {{ $t('common.load_more') }}
            </Button>
          </template>
        </BaseTable>
      </div>
    </section>
  </div>
</template>
